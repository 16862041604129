import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyleRoot } from 'radium';

import Loading from '../components/loader';
import Styles from '../styles';

const Main = lazy(() => import('./main'));
const Gender = lazy(() => import('./gender'));

export default () => {
  const { loaded } = useSelector((state) => state.app);

  return (
    <StyleRoot className='fill-available'>
      <Styles />
      {!loaded && <Loading />}
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            <Route exact path='/' element={<Main />} />
            <Route exact path='/gender' element={<Gender />} />
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      </Suspense>
    </StyleRoot>
  );
}
