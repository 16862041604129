import React from 'react';
import Radium from 'radium';
import { compose } from 'recompose';

import { white } from 'src/constants/colors';
import { ReactComponent as Icon } from './icons/preloader.svg';

export default compose(Radium)(() => (
  <div style={styles.root}>
    <div style={styles.spinner}>
      <Icon width="50px" height="50px" />
    </div>
  </div>
));

const spinnerKeyframe = Radium.keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
});

const styles = {
  root: {
    backgroundColor: white,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    left: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  spinner: {
    display: 'flex',
    animation: 'x 1s linear infinite',
    animationName: spinnerKeyframe,
  },
};
