import React from "react";
import { Style } from "radium";

import { primary } from "../constants/colors";

export default () => (
  <Style
    scopeSelector=".highlighted-title"
    rules={{
      strong: {
        color: primary,
        fontWeight: "inherit",
      },
      bold: {
        fontWeight: "bold",
      },
    }}
  />
);
