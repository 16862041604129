import { SET } from './types';

export const VARIANTS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

export const initialState = {
  buttonTitle: '',
  pageClickMode: false,
  autoSkip: 30, // in seconds
  contentVariant: VARIANTS.FIRST,
  genderTitles: {
    title: '',
    subTitle: '',
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };
    default:
      return state;
  }
}
