export const black = '#2d2d2d';
export const white = '#ffffff';
export const transparent = 'transparent';
export const gray = '#F2F2F2';
export const primary = '#2efced';
export const darkPink = '#d16755';
export const lightOrange = '#EE8F61';
export const secondLightOrange = '#FDEEE4';
export const spruce = '#0d6056';

export const buttonGradientInactive = `linear-gradient(99deg, #b8b9c3, #cfd0d5)`;
export const backgroundGradient = ``;
